
import axios from 'axios';
export default {
    
    async createOffHours(params)  {
        return await axios.post(`off_hours/create` , params)
    },
    async createOffHoursList(params)  {
        return await axios.post(`off_hours/create/list` , params)
    },
    async updateOffHoursColumn(column , value , data)  {
        return await axios.put(`off_hours/update_list?${column}=${value}` , data)
    },
    async deleteOffHoursList(list)  {
        return await axios.delete(`off_hours/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportOffHours(column , value)  {
        return await axios.get(`off_hours/report?${column}=${value}`)
    },
    async getAllOffHours()  {
        return await axios.get(`off_hours/all`)
    },
    async getOneOffHours(off_hour_id)  {
        return await axios.get(`off_hours/all/${off_hour_id}`)
    },
    async getOffHoursByColumn(column , value)  {
        return await axios.get(`off_hours/filter?column=${column}&value=${value}`)
    },
    async deleteOffHours(off_hour_id)  {
        return await axios.delete(`off_hours/delete/${off_hour_id}`)
    },
    async updateOffHours(off_hour_id , params)  {
        return await axios.put(`off_hours/update/${off_hour_id}` , params)
    }
}